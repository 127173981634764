import { createRouter, createWebHistory, type RouteLocationNormalizedGeneric } from 'vue-router'
import { useAuth, useClerkProvider } from 'vue-clerk'
import { until } from '@vueuse/core'
import { useLinksStore } from '@/stores/links'

const Dashboard = () => import('@/views/Dashboard/Dashboard.vue')

const LinkList = () => import('@/views/Links/List/LinkList.vue')
const LinksCTA = () => import('@/views/Links/LinksCTA.vue')

const Login = () => import('@/views/Login/Login.vue')
const SignUp = () => import('@/views/SignUp/SignUp.vue')
const AuthCallback = () => import('@/views/AuthCallback.vue')
const Checkout = () => import('@/views/Checkout.vue')

const AnalyticsView = () => import('@/views/Analytics/AnalyticsView.vue')
const AnalyticsCTA = () => import('@/views/Analytics/AnalyticsCTA.vue')

const Settings = () => import('@/views/Settings/Settings.vue')
const SettingsNavigation = () => import('@/views/Settings/SettingsNavigation.vue')
const SettingsCTA = () => import('@/views/Settings/SettingsCTA.vue')

const SubscriptionManagement = () => import('@/views/Settings/Subscription/SubscriptionManagement.vue')
const DomainManagement = () => import('@/views/Settings/Domains/DomainManagement.vue')
const DomainManagementCTA = () => import('@/views/Settings/Domains/DomainManagementCTA.vue')
const APIKeyManagement = () => import('@/views/Settings/APIKeys/APIKeyManagement.vue')
const APIKeyManagementCTA = () => import('@/views/Settings/APIKeys/APIKeyManagementCTA.vue')

const AccountPortal = () => import('@/views/AccountPortal.vue')

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                title: 'Short Menu – Dashboard',
                authIfNeeded: true
            },
            children: [
                {
                    path: '/links',
                    name: 'links',
                    components: {
                        default: LinkList,
                        cta: LinksCTA
                    },
                    meta: {
                        heading: 'All Links',
                        title: 'Short Menu – All Links'
                    },
                    children: [
                        {
                            path: ':id',
                            redirect: to => {
                                return { name: 'link-analytics', params: { linkId: to.params.id }, query: to.query }
                            }
                        }
                    ]
                },
                {
                    path: '/analytics',
                    name: 'analytics',
                    components: {
                        default: AnalyticsView,
                        cta: AnalyticsCTA
                    },
                    meta: {
                        heading: 'Analytics',
                        title: 'Short Menu – Analytics',
                        compactVerticalHeader: true
                    },
                    children: [
                        {
                            path: 'link/:linkId',
                            name: 'link-analytics',
                            components: {
                                default: AnalyticsView,
                                cta: AnalyticsCTA
                            }
                        }
                    ]
                },
                {
                    path: '/tags/:ids+',
                    name: 'tags',
                    components: {
                        default: LinkList,
                        cta: LinksCTA
                    },
                    props: {
                        default: route => ({ scopedTagIds: route.params.ids }),
                        cta: route => ({ scopedTagIds: route.params.ids })
                    },
                    meta: {
                        getHeading: async (route: RouteLocationNormalizedGeneric) => {
                            const singleTag = route.params.ids && route.params.ids.length === 1
                            if (!singleTag) {
                                return 'Links'
                            }

                            const tagId = route.params.ids[0]
                            const linksStore = useLinksStore()
                            const tagName = linksStore.tags.find(tag => tag.id === tagId)?.name
                            if (tagName) {
                                return tagName
                            }
                            await linksStore.reloadTags()
                            return linksStore.tags.find(tag => tag.id === tagId)?.name || ''
                        },
                        title: 'Short Menu – Links'
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    components: {
                        default: Settings,
                        nav: SettingsNavigation,
                        cta: SettingsCTA
                    },
                    meta: {
                        title: 'Short Menu – Settings',
                        heading: 'Settings'
                    },
                    children: [
                        {
                            path: 'subscription',
                            name: 'subscription',
                            component: SubscriptionManagement,
                            meta: {
                                title: 'Short Menu – Subscription'
                            }
                        },
                        {
                            path: 'domains',
                            name: 'domains',
                            components: {
                                default: DomainManagement,
                                cta: DomainManagementCTA
                            },
                            meta: {
                                title: 'Short Menu – Domains',
                            }
                        },
                        {
                            path: 'api-keys',
                            name: 'api-keys',
                            components: {
                                default: APIKeyManagement,
                                cta: APIKeyManagementCTA
                            },
                            meta: {
                                title: 'Short Menu – API Keys'
                            }
                        }
                    ]
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: 'Short Menu – Login',
                skipIfAuth: true
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUp,
            meta: {
                title: 'Short Menu – Sign Up',
                skipIfAuth: true
            }
        },
        {
            path: '/oauth-callback',
            component: AuthCallback,
            meta: {
                title: 'Short Menu - Loading…'
            }
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: Checkout,
            meta: {
                title: 'Short Menu – Checkout',
                authIfNeeded: true
            }
        },
        {
            path: '/account-portal',
            name: 'account-portal',
            component: AccountPortal,
            meta: {
                title: 'My Account | Short Menu',
            }
        }
    ]
})

router.beforeEach(async (to, from) => {
    const { isClerkLoaded } = useClerkProvider()
    if (!isClerkLoaded.value) {
        await until(isClerkLoaded).toBe(true)
    }
})

router.afterEach(route => {
    document.title = route.meta.title as string || 'Short Menu'
})

router.beforeResolve((to, from, next) => {
    const { isSignedIn } = useAuth()
    
    if (to.matched.some(record => record.meta.authIfNeeded) && isSignedIn.value !== true) {
        next({
            name: 'login',
            query: to.fullPath === '/' ? undefined : { destination: to.fullPath }
        })

        return
    }

    if (to.matched.some(record => record.meta.skipIfAuth) && isSignedIn.value === true) {
        const destination = to.query.destination as string | undefined
        if (destination) {
            next(destination)
            return
        }

        next({
            name: 'links'
        })

        return
    }

    if (to.name === 'settings') {
        next({
            name: 'subscription'
        })
        
        return
    }

    if (to.name === 'dashboard') {
        next({
            name: 'links'
        })

        return
    }

    next()
})

export default router
